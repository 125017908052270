// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderWithDelay_slideLeftcontainer__RGKLj {
    animation: RenderWithDelay_slideFromLeft__PBOvW 1.5s ease-in-out forwards;
}

.RenderWithDelay_slideRightcontainer__VIgL2 {
    animation: RenderWithDelay_slideFromRight__wOehc 1.5s ease-in-out forwards;
}

.RenderWithDelay_slideUpcontainer__1-q5c {
    animation: RenderWithDelay_slideUp__lDHAY 1.5s ease-in-out forwards;
}

@keyframes RenderWithDelay_slideFromRight__wOehc {
    from {
        transform: translateX(var(--transitionDistance, 100%));
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes RenderWithDelay_slideFromLeft__PBOvW {
    from {
        transform: translateX(var(--transitionDistance, -100%));
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes RenderWithDelay_slideUp__lDHAY {
    from {
        transform: translateY(var(--transitionDistance, 100%));
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/RenderWithDelay.module.css"],"names":[],"mappings":"AAAA;IACI,yEAAkD;AACtD;;AAEA;IACI,0EAAmD;AACvD;;AAEA;IACI,mEAA4C;AAChD;;AAEA;IACI;QACI,sDAAsD;QACtD,UAAU;IACd;;IAEA;QACI,wBAAwB;QACxB,UAAU;IACd;AACJ;;AAEA;IACI;QACI,uDAAuD;QACvD,UAAU;IACd;;IAEA;QACI,yBAAyB;QACzB,UAAU;IACd;AACJ;;AAEA;IACI;QACI,sDAAsD;QACtD,UAAU;IACd;;IAEA;QACI,yBAAyB;QACzB,UAAU;IACd;AACJ","sourcesContent":[".slideLeftcontainer {\n    animation: slideFromLeft 1.5s ease-in-out forwards;\n}\n\n.slideRightcontainer {\n    animation: slideFromRight 1.5s ease-in-out forwards;\n}\n\n.slideUpcontainer {\n    animation: slideUp 1.5s ease-in-out forwards;\n}\n\n@keyframes slideFromRight {\n    from {\n        transform: translateX(var(--transitionDistance, 100%));\n        opacity: 0;\n    }\n\n    to {\n        transform: translateX(0);\n        opacity: 1;\n    }\n}\n\n@keyframes slideFromLeft {\n    from {\n        transform: translateX(var(--transitionDistance, -100%));\n        opacity: 0;\n    }\n\n    to {\n        transform: translateX(0%);\n        opacity: 1;\n    }\n}\n\n@keyframes slideUp {\n    from {\n        transform: translateY(var(--transitionDistance, 100%));\n        opacity: 0;\n    }\n\n    to {\n        transform: translateY(0%);\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slideLeftcontainer": `RenderWithDelay_slideLeftcontainer__RGKLj`,
	"slideFromLeft": `RenderWithDelay_slideFromLeft__PBOvW`,
	"slideRightcontainer": `RenderWithDelay_slideRightcontainer__VIgL2`,
	"slideFromRight": `RenderWithDelay_slideFromRight__wOehc`,
	"slideUpcontainer": `RenderWithDelay_slideUpcontainer__1-q5c`,
	"slideUp": `RenderWithDelay_slideUp__lDHAY`
};
export default ___CSS_LOADER_EXPORT___;
