// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderListOfItemsInACircle_container__NzC3p {
    width: 100%;
    height: 100%;
    position: relative;    
}

.RenderListOfItemsInACircle_itemContainer__AxcT8 {
    width: 0%;
    height: 0%;
    position: absolute;
}

.RenderListOfItemsInACircle_item__ixEiw {
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/RenderListOfItemsInACircle.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,kBAAkB;AACtB;;AAEA;AACA","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;    \n}\n\n.itemContainer {\n    width: 0%;\n    height: 0%;\n    position: absolute;\n}\n\n.item {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RenderListOfItemsInACircle_container__NzC3p`,
	"itemContainer": `RenderListOfItemsInACircle_itemContainer__AxcT8`,
	"item": `RenderListOfItemsInACircle_item__ixEiw`
};
export default ___CSS_LOADER_EXPORT___;
