/** @TODO */
import React from "react";
// eslint-disable-next-line
import styles from "./ErrorPage.module.css";

const ErrorPage = () => {
  return <div>Error PAGE</div>;
};

export default ErrorPage;
