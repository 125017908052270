// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePagePTwo_extraSmall_container__CQGMI {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

/* Main Container Css Styles */
.HomePagePTwo_extraSmall_mainContainer__ATAlT {
  width: 100%;
  height: 100%;
  flex: 1 1 40%;
  margin: 1.5% 5% 0px 2%;
}

.HomePagePTwo_extraSmall_mainContainerContentGraphicPre__zXnAo {
  width: 100%;
  height: 100%;
}

.HomePagePTwo_extraSmall_mainContentContainer__1adVY {
  font-size: 96%;
  color: #5b5b5b;
  font-family: system-ui;
}


/* Sub Containers Css Styles */
.HomePagePTwo_extraSmall_subContainersContainer__ObtnR {
  flex: 1 1 50%;
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.HomePagePTwo_extraSmall_subContainerSection__MdiXn {
  width: 75%;
  height: 75%;
}

.HomePagePTwo_extraSmall_metaDataViewerSchedulerContainer__gd1dA {
  z-index: -1;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/HomePage/HomePagePTwo_extraSmall.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,8BAA8B;AAC9B;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,cAAc;EACd,sBAAsB;AACxB;;;AAGA,8BAA8B;AAC9B;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: space-evenly;\n  align-items: center;\n  overflow: hidden;\n}\n\n/* Main Container Css Styles */\n.mainContainer {\n  width: 100%;\n  height: 100%;\n  flex: 1 1 40%;\n  margin: 1.5% 5% 0px 2%;\n}\n\n.mainContainerContentGraphicPre {\n  width: 100%;\n  height: 100%;\n}\n\n.mainContentContainer {\n  font-size: 96%;\n  color: #5b5b5b;\n  font-family: system-ui;\n}\n\n\n/* Sub Containers Css Styles */\n.subContainersContainer {\n  flex: 1 1 50%;\n  position: relative;\n  width: 80%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-content: center;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.subContainerSection {\n  width: 75%;\n  height: 75%;\n}\n\n.metaDataViewerSchedulerContainer {\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePagePTwo_extraSmall_container__CQGMI`,
	"mainContainer": `HomePagePTwo_extraSmall_mainContainer__ATAlT`,
	"mainContainerContentGraphicPre": `HomePagePTwo_extraSmall_mainContainerContentGraphicPre__zXnAo`,
	"mainContentContainer": `HomePagePTwo_extraSmall_mainContentContainer__1adVY`,
	"subContainersContainer": `HomePagePTwo_extraSmall_subContainersContainer__ObtnR`,
	"subContainerSection": `HomePagePTwo_extraSmall_subContainerSection__MdiXn`,
	"metaDataViewerSchedulerContainer": `HomePagePTwo_extraSmall_metaDataViewerSchedulerContainer__gd1dA`
};
export default ___CSS_LOADER_EXPORT___;
