// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePagePFour_extraSmall_landscape_container__Q29wu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;    
}

.HomePagePFour_extraSmall_landscape_subContainer__1xLnQ {
    width: 80%;
    height: 80%;
}

.HomePagePFour_extraSmall_landscape_backgroundImg__P0Og2 {
    width: 100%;
}

.HomePagePFour_extraSmall_landscape_textsContainer__GiH6I {    
}

.HomePagePFour_extraSmall_landscape_text1Container__7Rryy {
    font-family: system-ui;
    font-size: 64%;
    font-weight: 300;
    margin: 3% 0px 2% 0px;
    color: black;
}

.HomePagePFour_extraSmall_landscape_text2Container__PVRkU {
    font-family: system-ui;
    font-size: 64%;
    font-weight: 300;
    margin: -2% 0px 3% 0px;
    color: black;
}

.HomePagePFour_extraSmall_landscape_textFocus__yC2sq {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/HomePage/HomePagePFour_extraSmall_landscape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;AACA;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;    \n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;\n}\n\n.textsContainer {    \n}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 64%;\n    font-weight: 300;\n    margin: 3% 0px 2% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 64%;\n    font-weight: 300;\n    margin: -2% 0px 3% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePagePFour_extraSmall_landscape_container__Q29wu`,
	"subContainer": `HomePagePFour_extraSmall_landscape_subContainer__1xLnQ`,
	"backgroundImg": `HomePagePFour_extraSmall_landscape_backgroundImg__P0Og2`,
	"textsContainer": `HomePagePFour_extraSmall_landscape_textsContainer__GiH6I`,
	"text1Container": `HomePagePFour_extraSmall_landscape_text1Container__7Rryy`,
	"text2Container": `HomePagePFour_extraSmall_landscape_text2Container__PVRkU`,
	"textFocus": `HomePagePFour_extraSmall_landscape_textFocus__yC2sq`
};
export default ___CSS_LOADER_EXPORT___;
