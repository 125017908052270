// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPFour_container__SyGYc {    
    width: 100%;
    height: 100%;    
}

.SkillsPFour_htmlContentSmallImg0__3FKzT {
    width: 100%;    
    height: 100%;
    cursor: pointer;
}

.SkillsPFour_htmlContentSmallImg1__mUhpg {
    width: 118px;
    height: 118px;
    cursor: pointer;
}

.SkillsPFour_htmlContentSmallImg2__AdnPp {
    width: 100%;   
    height: 100%; 
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPFour.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".container {    \n    width: 100%;\n    height: 100%;    \n}\n\n.htmlContentSmallImg0 {\n    width: 100%;    \n    height: 100%;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg1 {\n    width: 118px;\n    height: 118px;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg2 {\n    width: 100%;   \n    height: 100%; \n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPFour_container__SyGYc`,
	"htmlContentSmallImg0": `SkillsPFour_htmlContentSmallImg0__3FKzT`,
	"htmlContentSmallImg1": `SkillsPFour_htmlContentSmallImg1__mUhpg`,
	"htmlContentSmallImg2": `SkillsPFour_htmlContentSmallImg2__AdnPp`
};
export default ___CSS_LOADER_EXPORT___;
