// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollingDefaultStateDivs_rotatingDivsContainer__rmh6E {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

.ScrollingDefaultStateDivs_rotatingDiv__UjgAj {
    width: 100%;
    height: 100%;
    transform-origin: center;
    flex: 1 1 100%;
}

.ScrollingDefaultStateDivs_scrollIconContainer__2J36I {
    position: relative;
    width: 0px;
    margin: 16% 0px 0px 0px;
}

.ScrollingDefaultStateDivs_scrollSubIcon__gUI7Z {
    width: 69px;
}

.ScrollingDefaultStateDivs_scrollIcon__Pfe0s {
    width: 50px;
    transform: rotate(270deg);
}`, "",{"version":3,"sources":["webpack://./src/modules/components/presentationViewer/ScrollingDefaultStateDivs.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":[".rotatingDivsContainer {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.rotatingDiv {\n    width: 100%;\n    height: 100%;\n    transform-origin: center;\n    flex: 1 1 100%;\n}\n\n.scrollIconContainer {\n    position: relative;\n    width: 0px;\n    margin: 16% 0px 0px 0px;\n}\n\n.scrollSubIcon {\n    width: 69px;\n}\n\n.scrollIcon {\n    width: 50px;\n    transform: rotate(270deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotatingDivsContainer": `ScrollingDefaultStateDivs_rotatingDivsContainer__rmh6E`,
	"rotatingDiv": `ScrollingDefaultStateDivs_rotatingDiv__UjgAj`,
	"scrollIconContainer": `ScrollingDefaultStateDivs_scrollIconContainer__2J36I`,
	"scrollSubIcon": `ScrollingDefaultStateDivs_scrollSubIcon__gUI7Z`,
	"scrollIcon": `ScrollingDefaultStateDivs_scrollIcon__Pfe0s`
};
export default ___CSS_LOADER_EXPORT___;
