// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePagePFive_container__qyu3- {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.HomePagePFive_subContainer__xbr2W {
    width: 80%;
    height: 80%;
}

.HomePagePFive_backgroundImg__1Jiky {
    width: 100%;
    height: 100%;
}

.HomePagePFive_textsContainer__xSmFM {}

.HomePagePFive_text1Container__Y8Drf {
    font-family: system-ui;
    font-size: 21px;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.HomePagePFive_text2Container__Xbur0 {
    font-family: system-ui;
    font-size: 21px;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.HomePagePFive_textFocus__YugZL {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 10px 0px 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePagePFive.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA,sCAAiB;;AAEjB;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,wBAAwB;AAC5B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;\n    height: 100%;\n}\n\n.textsContainer {}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 21px;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 21px;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 10px 0px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePagePFive_container__qyu3-`,
	"subContainer": `HomePagePFive_subContainer__xbr2W`,
	"backgroundImg": `HomePagePFive_backgroundImg__1Jiky`,
	"textsContainer": `HomePagePFive_textsContainer__xSmFM`,
	"text1Container": `HomePagePFive_text1Container__Y8Drf`,
	"text2Container": `HomePagePFive_text2Container__Xbur0`,
	"textFocus": `HomePagePFive_textFocus__YugZL`
};
export default ___CSS_LOADER_EXPORT___;
