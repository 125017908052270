// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactPFive_container__xi0qY {
    width: 100%;        
    height: calc(65vh - 70px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;               
  }

.ContactPFive_listOfReviewsContainer__MIawm {
    width: 85%;
    height: 100%;  
}

.ContactPFive_addButton__K0Eam {
  width: 45%;
  cursor: pointer;
  animation: ContactPFive_focusTextAnimation__r7irq 1.5s forwards;
}

/* [ANIMATION SECTION] */
@keyframes ContactPFive_focusTextAnimation__r7irq {
  from {    
    opacity: 0;
    filter: blur(5px);
  }
  to {    
    opacity: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/ContactPFive.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;EACrB;;AAEF;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,+DAA2C;AAC7C;;AAEA,wBAAwB;AACxB;EACE;IACE,UAAU;IACV,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n    width: 100%;        \n    height: calc(65vh - 70px);\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;               \n  }\n\n.listOfReviewsContainer {\n    width: 85%;\n    height: 100%;  \n}\n\n.addButton {\n  width: 45%;\n  cursor: pointer;\n  animation: focusTextAnimation 1.5s forwards;\n}\n\n/* [ANIMATION SECTION] */\n@keyframes focusTextAnimation {\n  from {    \n    opacity: 0;\n    filter: blur(5px);\n  }\n  to {    \n    opacity: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactPFive_container__xi0qY`,
	"listOfReviewsContainer": `ContactPFive_listOfReviewsContainer__MIawm`,
	"addButton": `ContactPFive_addButton__K0Eam`,
	"focusTextAnimation": `ContactPFive_focusTextAnimation__r7irq`
};
export default ___CSS_LOADER_EXPORT___;
