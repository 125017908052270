// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnderlineHoverAnimation_container__vn-tI {
  position: relative;
  cursor: pointer;        
}

.UnderlineHoverAnimation_content__Kq\\+Nk {
  width: 100%;
  height: 100%;
  transition: width 0.5s ease;
}

.UnderlineHoverAnimation_content__Kq\\+Nk:hover {  
  width: var(--hover-width);    
}
  
.UnderlineHoverAnimation_container__vn-tI:hover .UnderlineHoverAnimation_underline__coh8u {      
  animation: UnderlineHoverAnimation_underlineAnimation__S-ifh 0.5s ease forwards;
}
  
.UnderlineHoverAnimation_underline__coh8u {
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0;
  background-color: #eb986c;
}

@keyframes UnderlineHoverAnimation_underlineAnimation__S-ifh {
  0% {
    width: 0;
  }
  100% {
    width: var(--hover-width);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/animations/UnderlineHoverAnimation.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+EAAgD;AAClD;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,YAAY;EACZ,WAAW;EACX,QAAQ;EACR,yBAAyB;AAC3B;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  position: relative;\n  cursor: pointer;        \n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  transition: width 0.5s ease;\n}\n\n.content:hover {  \n  width: var(--hover-width);    \n}\n  \n.container:hover .underline {      \n  animation: underlineAnimation 0.5s ease forwards;\n}\n  \n.underline {\n  position: absolute;\n  left: 0;\n  bottom: -2px;\n  height: 2px;\n  width: 0;\n  background-color: #eb986c;\n}\n\n@keyframes underlineAnimation {\n  0% {\n    width: 0;\n  }\n  100% {\n    width: var(--hover-width);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UnderlineHoverAnimation_container__vn-tI`,
	"content": `UnderlineHoverAnimation_content__Kq+Nk`,
	"underline": `UnderlineHoverAnimation_underline__coh8u`,
	"underlineAnimation": `UnderlineHoverAnimation_underlineAnimation__S-ifh`
};
export default ___CSS_LOADER_EXPORT___;
