// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_Pre_PTwo_extraSmall_container__dptO6 {
  width: 100vw;
  height: 90vh;
  overflow-y: hidden;
}

.HomePage_Pre_PTwo_extraSmall_viewItem__AclyE {
  width: 70%;
  height: 30%;
  margin: 0px 0px 0px -4%;
  animation: HomePage_Pre_PTwo_extraSmall_renderViewAnimation__4ShCc 3s ease-in-out;
}

.HomePage_Pre_PTwo_extraSmall_iconDetail__RS45Q {
  width: 90%;
  height: 90%;
  display: flex;    
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: -7% 0px 0px 0px;
  font-family: system-ui;
  font-style: italic;  
  padding: 10px;
  box-sizing: border-box;
  word-wrap: break-word; /* Break long words */
  word-break: break-word;
  overflow: hidden;
}

.HomePage_Pre_PTwo_extraSmall_contactIconsContainer__i2HDy {
  flex: 0 0 12%;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 19px;
}

.HomePage_Pre_PTwo_extraSmall_contactIcon__4Tsw6 {
  width: 100%;
  position: relative;
  cursor: pointer;
}


@keyframes HomePage_Pre_PTwo_extraSmall_renderViewAnimation__4ShCc {
  0% {
    opacity: 30%;
    filter: blur(1px);
  }
  100% {
    opacity: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/HomePage/HomePage_Pre_PTwo_extraSmall.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,iFAA6C;AAC/C;;AAEA;EACE,UAAU;EACV,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,qBAAqB,EAAE,qBAAqB;EAC5C,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;;AAGA;EACE;IACE,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  width: 100vw;\n  height: 90vh;\n  overflow-y: hidden;\n}\n\n.viewItem {\n  width: 70%;\n  height: 30%;\n  margin: 0px 0px 0px -4%;\n  animation: renderViewAnimation 3s ease-in-out;\n}\n\n.iconDetail {\n  width: 90%;\n  height: 90%;\n  display: flex;    \n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  margin: -7% 0px 0px 0px;\n  font-family: system-ui;\n  font-style: italic;  \n  padding: 10px;\n  box-sizing: border-box;\n  word-wrap: break-word; /* Break long words */\n  word-break: break-word;\n  overflow: hidden;\n}\n\n.contactIconsContainer {\n  flex: 0 0 12%;\n  width: 80%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #ffffff;\n  border-radius: 19px;\n}\n\n.contactIcon {\n  width: 100%;\n  position: relative;\n  cursor: pointer;\n}\n\n\n@keyframes renderViewAnimation {\n  0% {\n    opacity: 30%;\n    filter: blur(1px);\n  }\n  100% {\n    opacity: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePage_Pre_PTwo_extraSmall_container__dptO6`,
	"viewItem": `HomePage_Pre_PTwo_extraSmall_viewItem__AclyE`,
	"renderViewAnimation": `HomePage_Pre_PTwo_extraSmall_renderViewAnimation__4ShCc`,
	"iconDetail": `HomePage_Pre_PTwo_extraSmall_iconDetail__RS45Q`,
	"contactIconsContainer": `HomePage_Pre_PTwo_extraSmall_contactIconsContainer__i2HDy`,
	"contactIcon": `HomePage_Pre_PTwo_extraSmall_contactIcon__4Tsw6`
};
export default ___CSS_LOADER_EXPORT___;
