// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HoverComponent_container__c2qgt {
    width: 100%;
    height: 100%;
    animation: HoverComponent_fadeIn__2yEW\\+ 0.8s forwards;
}
  
  @keyframes HoverComponent_fadeIn__2yEW\\+ {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/HoverComponent.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sDAA+B;AACnC;;EAEE;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    animation: fadeIn 0.8s forwards;\n}\n  \n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HoverComponent_container__c2qgt`,
	"fadeIn": `HoverComponent_fadeIn__2yEW+`
};
export default ___CSS_LOADER_EXPORT___;
