// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BioPOne_extraSmall_container__t\\+958 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;    
}

.BioPOne_extraSmall_subContainer__\\+JK9v {
    width: 90%;
    height: 90%;
    position: relative;
    top: 7%;
}

.BioPOne_extraSmall_backgroundImg__Qwrbo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BioPOne_extraSmall_textsContainer__hD5wu {
    
}

.BioPOne_extraSmall_text1Container__dEvfk {
    width: 100%;
    height: 100%;
    font-family: system-ui;
    font-size: 68%;
    font-weight: 300;
    margin: -10% 0px 4% 0px;
    color: black;
}

.BioPOne_extraSmall_text2Container__hBw0I {
    font-family: system-ui;
    font-size: 68%;
    font-weight: 300;
    margin: 3% 0px 11% 0px;
    color: black;
}

.BioPOne_extraSmall_textFocus__RU08O {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/BioPage/BioPOne_extraSmall.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,OAAO;AACX;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;    \n}\n\n.subContainer {\n    width: 90%;\n    height: 90%;\n    position: relative;\n    top: 7%;\n}\n\n.backgroundImg {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.textsContainer {\n    \n}\n\n.text1Container {\n    width: 100%;\n    height: 100%;\n    font-family: system-ui;\n    font-size: 68%;\n    font-weight: 300;\n    margin: -10% 0px 4% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 68%;\n    font-weight: 300;\n    margin: 3% 0px 11% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BioPOne_extraSmall_container__t+958`,
	"subContainer": `BioPOne_extraSmall_subContainer__+JK9v`,
	"backgroundImg": `BioPOne_extraSmall_backgroundImg__Qwrbo`,
	"textsContainer": `BioPOne_extraSmall_textsContainer__hD5wu`,
	"text1Container": `BioPOne_extraSmall_text1Container__dEvfk`,
	"text2Container": `BioPOne_extraSmall_text2Container__hBw0I`,
	"textFocus": `BioPOne_extraSmall_textFocus__RU08O`
};
export default ___CSS_LOADER_EXPORT___;
