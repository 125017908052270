// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPSeven_container__B19NR {    
    width: 100%;
    height: 100%;    
}

.SkillsPSeven_htmlContentSmallImg0__0mKNL {
    width: 100%;    
    height: 100%;
    cursor: pointer;
}

.SkillsPSeven_htmlContentSmallImg1__e7T1m {
    width: 118px;
    height: 118px;
    cursor: pointer;
}

.SkillsPSeven_htmlContentSmallImg2__EOC8W {
    width: 253px;   
    height: 100%; 
    margin: 0px 0px -19px -62px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPSeven.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,eAAe;AACnB","sourcesContent":[".container {    \n    width: 100%;\n    height: 100%;    \n}\n\n.htmlContentSmallImg0 {\n    width: 100%;    \n    height: 100%;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg1 {\n    width: 118px;\n    height: 118px;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg2 {\n    width: 253px;   \n    height: 100%; \n    margin: 0px 0px -19px -62px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPSeven_container__B19NR`,
	"htmlContentSmallImg0": `SkillsPSeven_htmlContentSmallImg0__0mKNL`,
	"htmlContentSmallImg1": `SkillsPSeven_htmlContentSmallImg1__e7T1m`,
	"htmlContentSmallImg2": `SkillsPSeven_htmlContentSmallImg2__EOC8W`
};
export default ___CSS_LOADER_EXPORT___;
