// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfiniteRender_container__QvUKW {
    width: 100%;
    height: 100%;
}

.InfiniteRender_renderComponent__s28te {
    
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/InfiniteRender.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;AAEA","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n}\n\n.renderComponent {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InfiniteRender_container__QvUKW`,
	"renderComponent": `InfiniteRender_renderComponent__s28te`
};
export default ___CSS_LOADER_EXPORT___;
