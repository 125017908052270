// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeDisplay_codeContainer__-S4js {
    font-family: 'Courier New', Courier, monospace;    
    padding: 10px;     
    overflow: hidden;    
  }
  
  .CodeDisplay_codeContent__0Eeju {    
    /* white-space: pre;  Preserve whitespace and line breaks -> Think use rpe to preview code browse  examples */
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/components/contentViewer/CodeDisplay.module.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,6GAA6G;EAC/G","sourcesContent":[".codeContainer {\n    font-family: 'Courier New', Courier, monospace;    \n    padding: 10px;     \n    overflow: hidden;    \n  }\n  \n  .codeContent {    \n    /* white-space: pre;  Preserve whitespace and line breaks -> Think use rpe to preview code browse  examples */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeContainer": `CodeDisplay_codeContainer__-S4js`,
	"codeContent": `CodeDisplay_codeContent__0Eeju`
};
export default ___CSS_LOADER_EXPORT___;
