// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentViewerPatch1_container__XoTya {
  width: 100%;
  height: 100%;
  position: relative;
}

.ContentViewerPatch1_contentContainer__TgESS {
  text-align: center;
  position: absolute;
  top: 3%;
  right: 0%;
  width: 100%;
  height: 100%;
}

.ContentViewerPatch1_contentStageContainer__71xhA {
  width: 100%;
  height: 100%;
}

.ContentViewerPatch1_introductionContainer__FerNw {
  width: 100%;
  height: 100%;
}


.ContentViewerPatch1_titleContainer__u0f4u {
  margin-bottom: 20px;
}

.ContentViewerPatch1_title__Binjl {
  font-size: 24px;
  font-weight: bold;
}

.ContentViewerPatch1_lineHorizontal__VMs\\+M {
  width: 60%;
  height: 2px;
  background-color: #000;
  margin-bottom: 20px;
  margin-left: 20%;
}

.ContentViewerPatch1_textContainer__K5RFI {
  margin-bottom: 20px;
}

.ContentViewerPatch1_text__sHuet {
  font-size: 26px;
  color: #5b5b5b;
  font-family: system-ui;
}

/* Graphic Container */
.ContentViewerPatch1_graphicContainerOnTop__8IECm {
  border-radius: 10px;
  object-fit: cover;
  opacity: 50%;
  filter: blur(3px);
  animation: ContentViewerPatch1_renderingAnimation__8kDyx 5s ease-in-out;
}

.ContentViewerPatch1_graphicContainerOnBackground__RVwMC {
  border-radius: 10px;
  object-fit: cover;
  opacity: 0.5;
  filter: blur(3px);
}


/* Animation Section */
@keyframes ContentViewerPatch1_renderingAnimation__8kDyx {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/components/contentViewer/ContentViewerPatch1.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,sBAAsB;AACxB;;AAEA,sBAAsB;AACtB;EACE,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,uEAA4C;AAC9C;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;AACnB;;;AAGA,sBAAsB;AACtB;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.contentContainer {\n  text-align: center;\n  position: absolute;\n  top: 3%;\n  right: 0%;\n  width: 100%;\n  height: 100%;\n}\n\n.contentStageContainer {\n  width: 100%;\n  height: 100%;\n}\n\n.introductionContainer {\n  width: 100%;\n  height: 100%;\n}\n\n\n.titleContainer {\n  margin-bottom: 20px;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.lineHorizontal {\n  width: 60%;\n  height: 2px;\n  background-color: #000;\n  margin-bottom: 20px;\n  margin-left: 20%;\n}\n\n.textContainer {\n  margin-bottom: 20px;\n}\n\n.text {\n  font-size: 26px;\n  color: #5b5b5b;\n  font-family: system-ui;\n}\n\n/* Graphic Container */\n.graphicContainerOnTop {\n  border-radius: 10px;\n  object-fit: cover;\n  opacity: 50%;\n  filter: blur(3px);\n  animation: renderingAnimation 5s ease-in-out;\n}\n\n.graphicContainerOnBackground {\n  border-radius: 10px;\n  object-fit: cover;\n  opacity: 0.5;\n  filter: blur(3px);\n}\n\n\n/* Animation Section */\n@keyframes renderingAnimation {\n  0% {\n    opacity: 100%;\n  }\n\n  100% {\n    opacity: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContentViewerPatch1_container__XoTya`,
	"contentContainer": `ContentViewerPatch1_contentContainer__TgESS`,
	"contentStageContainer": `ContentViewerPatch1_contentStageContainer__71xhA`,
	"introductionContainer": `ContentViewerPatch1_introductionContainer__FerNw`,
	"titleContainer": `ContentViewerPatch1_titleContainer__u0f4u`,
	"title": `ContentViewerPatch1_title__Binjl`,
	"lineHorizontal": `ContentViewerPatch1_lineHorizontal__VMs+M`,
	"textContainer": `ContentViewerPatch1_textContainer__K5RFI`,
	"text": `ContentViewerPatch1_text__sHuet`,
	"graphicContainerOnTop": `ContentViewerPatch1_graphicContainerOnTop__8IECm`,
	"renderingAnimation": `ContentViewerPatch1_renderingAnimation__8kDyx`,
	"graphicContainerOnBackground": `ContentViewerPatch1_graphicContainerOnBackground__RVwMC`
};
export default ___CSS_LOADER_EXPORT___;
