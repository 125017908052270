// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleAndContentViewer_container__jwDOe {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}

.TitleAndContentViewer_titleContainer__aVnJ4 {
    margin-bottom: 20px;
  }
  
  .TitleAndContentViewer_title__z0jz\\+ {
    font-size: 24px;
    font-weight: bold;
  }
  
  .TitleAndContentViewer_lineHorizontal__QfYtm {
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-bottom: 20px;
  }
  
  .TitleAndContentViewer_contentContainer__o0EgS {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .TitleAndContentViewer_content__\\+o5bl {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/modules/components/contentViewer/TitleAndContentViewer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;IACI,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;IACzB,2BAA2B;IAC3B,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.titleContainer {\n    margin-bottom: 20px;\n  }\n  \n  .title {\n    font-size: 24px;\n    font-weight: bold;\n  }\n  \n  .lineHorizontal {\n    width: 100%;\n    height: 2px;\n    background-color: #000;\n    margin-bottom: 20px;\n  }\n  \n  .contentContainer {\n    width: 100%;\n    height: 100%;\n    margin-bottom: 20px;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: flex-start;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n  \n  .content {\n    width: 100%;\n    height: 100%;\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TitleAndContentViewer_container__jwDOe`,
	"titleContainer": `TitleAndContentViewer_titleContainer__aVnJ4`,
	"title": `TitleAndContentViewer_title__z0jz+`,
	"lineHorizontal": `TitleAndContentViewer_lineHorizontal__QfYtm`,
	"contentContainer": `TitleAndContentViewer_contentContainer__o0EgS`,
	"content": `TitleAndContentViewer_content__+o5bl`
};
export default ___CSS_LOADER_EXPORT___;
