// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderListOfMeanings_gradesContainer__5LqCc {
    width: 100%;
    height: 100%;    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px 0px 0px 3px;
  }
  
.RenderListOfMeanings_gradeContainer__b1CQK {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  
.RenderListOfMeanings_gradeContent__9BJcX {
    margin: 0px 0px 0px 3px
}

.RenderListOfMeanings_gradeScore__-d2YZ {
  margin: 0px 0px 0px 3px
}`, "",{"version":3,"sources":["webpack://./src/modules/components/presentationViewer/RenderListOfMeanings.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,2BAA2B;IAC3B,uBAAuB;IACvB,uBAAuB;EACzB;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;EACrB;;AAEF;IACI;AACJ;;AAEA;EACE;AACF","sourcesContent":[".gradesContainer {\n    width: 100%;\n    height: 100%;    \n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: flex-start;\n    justify-content: flex-start;\n    align-items: flex-start;\n    margin: 0px 0px 0px 3px;\n  }\n  \n.gradeContainer {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: flex-end;\n    justify-content: center;\n    align-items: center;\n  }\n  \n.gradeContent {\n    margin: 0px 0px 0px 3px\n}\n\n.gradeScore {\n  margin: 0px 0px 0px 3px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradesContainer": `RenderListOfMeanings_gradesContainer__5LqCc`,
	"gradeContainer": `RenderListOfMeanings_gradeContainer__b1CQK`,
	"gradeContent": `RenderListOfMeanings_gradeContent__9BJcX`,
	"gradeScore": `RenderListOfMeanings_gradeScore__-d2YZ`
};
export default ___CSS_LOADER_EXPORT___;
