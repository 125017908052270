// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoffeePThree_extraSmall_landscape_container__dWtxj {
  width: 100%;        
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;   
  margin: -5% 0px 0px -13px;        
}

.CoffeePThree_extraSmall_landscape_listOfReviewsContainer__1XoI7 {
  width: 85%;
  height: 100%;  
}

.CoffeePThree_extraSmall_landscape_addButton__tJ2Hv {
width: 45%;
cursor: pointer;
animation: CoffeePThree_extraSmall_landscape_focusTextAnimation__G7ecs 1.5s forwards;
}

/* [ANIMATION SECTION] */
@keyframes CoffeePThree_extraSmall_landscape_focusTextAnimation__G7ecs {
from {    
  opacity: 0;
  filter: blur(5px);
}
to {    
  opacity: 100%;
}
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/Coffee/CoffeePThree_extraSmall_landscape.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;AACA,UAAU;AACV,eAAe;AACf,oFAA2C;AAC3C;;AAEA,wBAAwB;AACxB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA","sourcesContent":[".container {\n  width: 100%;        \n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;   \n  margin: -5% 0px 0px -13px;        \n}\n\n.listOfReviewsContainer {\n  width: 85%;\n  height: 100%;  \n}\n\n.addButton {\nwidth: 45%;\ncursor: pointer;\nanimation: focusTextAnimation 1.5s forwards;\n}\n\n/* [ANIMATION SECTION] */\n@keyframes focusTextAnimation {\nfrom {    \n  opacity: 0;\n  filter: blur(5px);\n}\nto {    \n  opacity: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CoffeePThree_extraSmall_landscape_container__dWtxj`,
	"listOfReviewsContainer": `CoffeePThree_extraSmall_landscape_listOfReviewsContainer__1XoI7`,
	"addButton": `CoffeePThree_extraSmall_landscape_addButton__tJ2Hv`,
	"focusTextAnimation": `CoffeePThree_extraSmall_landscape_focusTextAnimation__G7ecs`
};
export default ___CSS_LOADER_EXPORT___;
