// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BioPageThree_extraSmall_container__gpUBf {
    width: 100vw;
    height: calc(100vh - 64px);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.BioPageThree_extraSmall_subContainer__gTVSr {
    width: 80%;
    height: 80%;
}

.BioPageThree_extraSmall_backgroundImg__\\+uUce {
    width: 100%;    
    height: 100%;    
}

.BioPageThree_extraSmall_textsContainer__maDqZ {

}

.BioPageThree_extraSmall_text1Container__qdNrv {
    font-family: system-ui;
    font-size: 82%;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.BioPageThree_extraSmall_textFocus__gJV\\+p {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/BioPage/BioPageThree_extraSmall.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;AAEA;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100vw;\n    height: calc(100vh - 64px);\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;    \n    height: 100%;    \n}\n\n.textsContainer {\n\n}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 82%;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BioPageThree_extraSmall_container__gpUBf`,
	"subContainer": `BioPageThree_extraSmall_subContainer__gTVSr`,
	"backgroundImg": `BioPageThree_extraSmall_backgroundImg__+uUce`,
	"textsContainer": `BioPageThree_extraSmall_textsContainer__maDqZ`,
	"text1Container": `BioPageThree_extraSmall_text1Container__qdNrv`,
	"textFocus": `BioPageThree_extraSmall_textFocus__gJV+p`
};
export default ___CSS_LOADER_EXPORT___;
