// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TypingTextAnimation_container__tjpsI {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  animation: TypingTextAnimation_typing__9bL7C 1s steps(40, end), TypingTextAnimation_blink-caret__f6YZG 0.75s step-end infinite;
}

@keyframes TypingTextAnimation_typing__9bL7C {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes TypingTextAnimation_blink-caret__f6YZG {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/animations/TypingTextAnimation.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,8HAAwE;AAC1E;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;;IAEE,yBAAyB;EAC3B;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  overflow: hidden;\n  white-space: nowrap;\n  display: inline-block;\n  animation: typing 1s steps(40, end), blink-caret 0.75s step-end infinite;\n}\n\n@keyframes typing {\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n}\n\n@keyframes blink-caret {\n  from,\n  to {\n    border-color: transparent;\n  }\n  50% {\n    border-color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TypingTextAnimation_container__tjpsI`,
	"typing": `TypingTextAnimation_typing__9bL7C`,
	"blink-caret": `TypingTextAnimation_blink-caret__f6YZG`
};
export default ___CSS_LOADER_EXPORT___;
