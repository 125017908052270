// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePagePThree_extraSmall_landscape_container__rMMci {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;    
}

.HomePagePThree_extraSmall_landscape_subContainer__x8pg0 {
    width: 80%;
    height: 80%;
}

.HomePagePThree_extraSmall_landscape_backgroundImg__TLL6G {
    width: 100%;
    margin: -5% 0px 0px 0px;
}

.HomePagePThree_extraSmall_landscape_textsContainer__FC38T {
    
}

.HomePagePThree_extraSmall_landscape_text1Container__BX\\+cX {
    font-family: system-ui;
    font-size: 63%;
    font-weight: 300;
    margin: 3% 0px 0% 0px;
    color: black;
}

.HomePagePThree_extraSmall_landscape_text2Container__yZE\\+x {
    font-family: system-ui;
    font-size: 65%;
    font-weight: 300;
    margin: 1% 0px 1% 0px;
    color: black;
}

.HomePagePThree_extraSmall_landscape_textFocus__02OQ4 {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/HomePage/HomePagePThree_extraSmall_landscape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B;;AAEA;;AAEA;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;    \n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;\n    margin: -5% 0px 0px 0px;\n}\n\n.textsContainer {\n    \n}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 63%;\n    font-weight: 300;\n    margin: 3% 0px 0% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 65%;\n    font-weight: 300;\n    margin: 1% 0px 1% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePagePThree_extraSmall_landscape_container__rMMci`,
	"subContainer": `HomePagePThree_extraSmall_landscape_subContainer__x8pg0`,
	"backgroundImg": `HomePagePThree_extraSmall_landscape_backgroundImg__TLL6G`,
	"textsContainer": `HomePagePThree_extraSmall_landscape_textsContainer__FC38T`,
	"text1Container": `HomePagePThree_extraSmall_landscape_text1Container__BX+cX`,
	"text2Container": `HomePagePThree_extraSmall_landscape_text2Container__yZE+x`,
	"textFocus": `HomePagePThree_extraSmall_landscape_textFocus__02OQ4`
};
export default ___CSS_LOADER_EXPORT___;
