// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimationWarningHarbinger_container__bdQ8m {    
    cursor: pointer;
    width: 98%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    margin: 0px 0px 0px 0px;
  }
  
  .AnimationWarningHarbinger_animatedComponent__Ywg-o {
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .AnimationWarningHarbinger_animatedComponent__Ywg-o:hover {
    transform: translateX(10px);
  }

  /* Description Section: */
  .AnimationWarningHarbinger_warningImg__Ejd6k {
    margin-left: 1px;
    width: 25px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 3;
  }
  
  .AnimationWarningHarbinger_details__kIue- {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 3px 0px 0px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .AnimationWarningHarbinger_detailsContent__rk9M7 {
    font-size: 14px;
  }
  
  .AnimationWarningHarbinger_container__bdQ8m:hover .AnimationWarningHarbinger_details__kIue- {
    display: block;
  }`, "",{"version":3,"sources":["webpack://./src/modules/components/animations/AnimationWarningHarbinger.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,uBAAuB;EACzB;;EAEA;IACE,sCAAsC;IACtC,eAAe;EACjB;;EAEA;IACE,2BAA2B;EAC7B;;EAEA,yBAAyB;EACzB;IACE,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,2CAA2C;IAC3C,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,uCAAuC;EACzC;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".container {    \n    cursor: pointer;\n    width: 98%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: flex-end;\n    margin: 0px 0px 0px 0px;\n  }\n  \n  .animatedComponent {\n    transition: transform 0.3s ease-in-out;\n    cursor: pointer;\n  }\n  \n  .animatedComponent:hover {\n    transform: translateX(10px);\n  }\n\n  /* Description Section: */\n  .warningImg {\n    margin-left: 1px;\n    width: 25px;\n    cursor: pointer;\n    border-radius: 50%;\n    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);\n    z-index: 3;\n  }\n  \n  .details {\n    position: relative;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    padding: 10px;\n    margin: 3px 0px 0px 0px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  }\n  \n  .detailsContent {\n    font-size: 14px;\n  }\n  \n  .container:hover .details {\n    display: block;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnimationWarningHarbinger_container__bdQ8m`,
	"animatedComponent": `AnimationWarningHarbinger_animatedComponent__Ywg-o`,
	"warningImg": `AnimationWarningHarbinger_warningImg__Ejd6k`,
	"details": `AnimationWarningHarbinger_details__kIue-`,
	"detailsContent": `AnimationWarningHarbinger_detailsContent__rk9M7`
};
export default ___CSS_LOADER_EXPORT___;
