// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BioPOne_container__ou\\+Rj {
    width: 100vw;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.BioPOne_subContainer__4jXl9 {
    width: 80%;
    height: 80%;
}

.BioPOne_backgroundImg__6dxjr {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BioPOne_textsContainer__B8VYH {}

.BioPOne_text1Container__PyEQV {
    font-family: system-ui;
    font-size: 21px;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.BioPOne_text2Container__WeFBc {
    font-family: system-ui;
    font-size: 21px;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.BioPOne_textFocus__KEoG2 {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/BioPage/BioPOne.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA,gCAAiB;;AAEjB;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100vw;\n    height: calc(100vh - 64px);\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.textsContainer {}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 21px;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 21px;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BioPOne_container__ou+Rj`,
	"subContainer": `BioPOne_subContainer__4jXl9`,
	"backgroundImg": `BioPOne_backgroundImg__6dxjr`,
	"textsContainer": `BioPOne_textsContainer__B8VYH`,
	"text1Container": `BioPOne_text1Container__PyEQV`,
	"text2Container": `BioPOne_text2Container__WeFBc`,
	"textFocus": `BioPOne_textFocus__KEoG2`
};
export default ___CSS_LOADER_EXPORT___;
