// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentViewerPatch2_container__DOxvc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ContentViewerPatch2_graphicContainer__\\+-Cso {
    flex: 0 0 50%;
    width: 100%;
    height: 100%;
} 

.ContentViewerPatch2_contentContainer__PiHJ\\+ {
    flex: 0 0 50%;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/contentViewer/ContentViewerPatch2.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.graphicContainer {\n    flex: 0 0 50%;\n    width: 100%;\n    height: 100%;\n} \n\n.contentContainer {\n    flex: 0 0 50%;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContentViewerPatch2_container__DOxvc`,
	"graphicContainer": `ContentViewerPatch2_graphicContainer__+-Cso`,
	"contentContainer": `ContentViewerPatch2_contentContainer__PiHJ+`
};
export default ___CSS_LOADER_EXPORT___;
