// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__B2Ebb {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App_mainBodyContainer__l6FSI {
  width: 100%;
  height: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".App {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}\n\n.mainBodyContainer {\n  width: 100%;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__B2Ebb`,
	"mainBodyContainer": `App_mainBodyContainer__l6FSI`
};
export default ___CSS_LOADER_EXPORT___;
