// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPThirteen_container__i6oPh {    
    width: 100%;
    height: 100%;    
}

.SkillsPThirteen_htmlContentSmallImg0__pE8K7 {
    width: 100%;    
    height: 100%;
    cursor: pointer;
}

.SkillsPThirteen_htmlContentSmallImg1__-E1m6 {
    width: 118px;
    height: 118px;
    cursor: pointer;
}

.SkillsPThirteen_htmlContentSmallImg2__s7uQ7 {
    width: 180px;   
    height: 140px; 
    margin: 0px 0px -19px -32px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPThirteen.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,eAAe;AACnB","sourcesContent":[".container {    \n    width: 100%;\n    height: 100%;    \n}\n\n.htmlContentSmallImg0 {\n    width: 100%;    \n    height: 100%;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg1 {\n    width: 118px;\n    height: 118px;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg2 {\n    width: 180px;   \n    height: 140px; \n    margin: 0px 0px -19px -32px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPThirteen_container__i6oPh`,
	"htmlContentSmallImg0": `SkillsPThirteen_htmlContentSmallImg0__pE8K7`,
	"htmlContentSmallImg1": `SkillsPThirteen_htmlContentSmallImg1__-E1m6`,
	"htmlContentSmallImg2": `SkillsPThirteen_htmlContentSmallImg2__s7uQ7`
};
export default ___CSS_LOADER_EXPORT___;
