// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigatorByHeaderpageView_container__JVixG {
    width: 100%;
    height: 100%;    
}

.NavigatorByHeaderpageView_routesContainer__DAUN5 {
    
}

.NavigatorByHeaderpageView_simplePorfolioContainer__ZYPwS {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
  }`, "",{"version":3,"sources":["webpack://./src/modules/view/pages/NavigatorByHeaderpageView.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;AAEA;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;EACtC","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;    \n}\n\n.routesContainer {\n    \n}\n\n.simplePorfolioContainer {\n    width: 100%;\n    height: 100%;\n    background-color: rgb(255, 255, 255);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NavigatorByHeaderpageView_container__JVixG`,
	"routesContainer": `NavigatorByHeaderpageView_routesContainer__DAUN5`,
	"simplePorfolioContainer": `NavigatorByHeaderpageView_simplePorfolioContainer__ZYPwS`
};
export default ___CSS_LOADER_EXPORT___;
