// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BioPOne_extraSmall_landscape_container__MqmLY {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;    
}

.BioPOne_extraSmall_landscape_subContainer__517jJ {
    width: 90%;
    height: 90%;
    position: relative;
    top: 14%;
}

.BioPOne_extraSmall_landscape_backgroundImg__wdIbW {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BioPOne_extraSmall_landscape_textsContainer__EJgH1 {
    
}

.BioPOne_extraSmall_landscape_text1Container__AOVKx {
    width: 100%;
    height: 100%;
    font-family: system-ui;
    font-size: 68%;
    font-weight: 300;
    margin: -10% 0px -2% 0px;
    color: black;
}

.BioPOne_extraSmall_landscape_text2Container__gMlKC {
    font-family: system-ui;
    font-size: 68%;
    font-weight: 300;
    margin: 3% 0px 11% 0px;
    color: black;
}

.BioPOne_extraSmall_landscape_textFocus__HU4Yp {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/BioPage/BioPOne_extraSmall_landscape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;    \n}\n\n.subContainer {\n    width: 90%;\n    height: 90%;\n    position: relative;\n    top: 14%;\n}\n\n.backgroundImg {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.textsContainer {\n    \n}\n\n.text1Container {\n    width: 100%;\n    height: 100%;\n    font-family: system-ui;\n    font-size: 68%;\n    font-weight: 300;\n    margin: -10% 0px -2% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 68%;\n    font-weight: 300;\n    margin: 3% 0px 11% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BioPOne_extraSmall_landscape_container__MqmLY`,
	"subContainer": `BioPOne_extraSmall_landscape_subContainer__517jJ`,
	"backgroundImg": `BioPOne_extraSmall_landscape_backgroundImg__wdIbW`,
	"textsContainer": `BioPOne_extraSmall_landscape_textsContainer__EJgH1`,
	"text1Container": `BioPOne_extraSmall_landscape_text1Container__AOVKx`,
	"text2Container": `BioPOne_extraSmall_landscape_text2Container__gMlKC`,
	"textFocus": `BioPOne_extraSmall_landscape_textFocus__HU4Yp`
};
export default ___CSS_LOADER_EXPORT___;
