// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPDefault_container__zfwhN {
    margin: 0px 0% 0px 4%;
    width: 90%;
    height: 90%;
    display: flex;
    overflow: hidden;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #dfdfdf;
    box-shadow: 4px 16px 8px 3px rgb(53 96 152 / 15%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.SkillsPDefault_gradeContent__OqoTv {
    width: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPDefault.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,0BAA0B;IAC1B,iBAAiB;IACjB,qBAAqB;IACrB,iDAAiD;IACjD,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".container {\n    margin: 0px 0% 0px 4%;\n    width: 90%;\n    height: 90%;\n    display: flex;\n    overflow: hidden;\n    border-bottom-style: solid;\n    border-width: 1px;\n    border-color: #dfdfdf;\n    box-shadow: 4px 16px 8px 3px rgb(53 96 152 / 15%);\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.gradeContent {\n    width: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPDefault_container__zfwhN`,
	"gradeContent": `SkillsPDefault_gradeContent__OqoTv`
};
export default ___CSS_LOADER_EXPORT___;
