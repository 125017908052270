// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_container__pI5pZ {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.Menu_subContainer__giHrv {
    width: 85%;
    height: 85%;
    position: relative;
}

.Menu_menuObjectsContainer__ieMxP {
    width: 600px;
    height: 600px;
    position: relative;
}

.Menu_iconContainer__Kmqyu {
    width: 20px; 
    height: 20px;  
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 4px 4px 10px rgb(0 0 0);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Menu_iconContainer__Kmqyu:hover {   
    box-shadow: 4px 4px 10px rgb(255 87 7);
}

.Menu_icons__0RoaZ {
    width: 60px;    
}
.Menu_icons__0RoaZ:hover {
    width: 70px;    
}

.Menu_titleContainer__jh9OL {} 

.Menu_description__GTHge {}`, "",{"version":3,"sources":["webpack://./src/pages/Menu/Menu.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,mCAAmC;IACnC,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,sCAAsC;AAC1C;;AAEA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA,6BAAiB;;AAEjB,0BAAc","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.subContainer {\n    width: 85%;\n    height: 85%;\n    position: relative;\n}\n\n.menuObjectsContainer {\n    width: 600px;\n    height: 600px;\n    position: relative;\n}\n\n.iconContainer {\n    width: 20px; \n    height: 20px;  \n    cursor: pointer;\n    border-radius: 100%;\n    box-shadow: 4px 4px 10px rgb(0 0 0);\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n.iconContainer:hover {   \n    box-shadow: 4px 4px 10px rgb(255 87 7);\n}\n\n.icons {\n    width: 60px;    \n}\n.icons:hover {\n    width: 70px;    \n}\n\n.titleContainer {} \n\n.description {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Menu_container__pI5pZ`,
	"subContainer": `Menu_subContainer__giHrv`,
	"menuObjectsContainer": `Menu_menuObjectsContainer__ieMxP`,
	"iconContainer": `Menu_iconContainer__Kmqyu`,
	"icons": `Menu_icons__0RoaZ`,
	"titleContainer": `Menu_titleContainer__jh9OL`,
	"description": `Menu_description__GTHge`
};
export default ___CSS_LOADER_EXPORT___;
