// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_extraSmall_PortraitAndLanscape_container__04PBq {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.Menu_extraSmall_PortraitAndLanscape_subContainer__nstBk {
    width: 85%;
    height: 85%;
    position: relative;
}

.Menu_extraSmall_PortraitAndLanscape_menuObjectsContainer__P9r6o {
    width: 600px;
    height: 600px;
    position: relative;
}

.Menu_extraSmall_PortraitAndLanscape_renderMenu_extraSmall_PortraitAndLanscapeObjectsContainer__bCgTK {
    width: 150%;
    height: 150%;
    display: flex;
    position: relative;
    top: -27%;
    right: 25%;
}

.Menu_extraSmall_PortraitAndLanscape_iconContainer__ATjE4 {
    width: 20px; 
    height: 20px;  
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 4px 4px 10px rgb(0 0 0);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Menu_extraSmall_PortraitAndLanscape_iconContainer__ATjE4:hover {   
    box-shadow: 4px 4px 10px rgb(255 87 7);
}

.Menu_extraSmall_PortraitAndLanscape_icons__UC6w3 {
    width: 60px;    
}
.Menu_extraSmall_PortraitAndLanscape_icons__UC6w3:hover {
    width: 70px;    
}

.Menu_extraSmall_PortraitAndLanscape_titleContainer__u9TpV {} 

.Menu_extraSmall_PortraitAndLanscape_description__n2N2s {
    font-family: system-ui;
    font-size: 90%;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/Menu/Menu_extraSmall_PortraitAndLanscape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,mCAAmC;IACnC,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,sCAAsC;AAC1C;;AAEA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA,4DAAiB;;AAEjB;IACI,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.subContainer {\n    width: 85%;\n    height: 85%;\n    position: relative;\n}\n\n.menuObjectsContainer {\n    width: 600px;\n    height: 600px;\n    position: relative;\n}\n\n.renderMenu_extraSmall_PortraitAndLanscapeObjectsContainer {\n    width: 150%;\n    height: 150%;\n    display: flex;\n    position: relative;\n    top: -27%;\n    right: 25%;\n}\n\n.iconContainer {\n    width: 20px; \n    height: 20px;  \n    cursor: pointer;\n    border-radius: 100%;\n    box-shadow: 4px 4px 10px rgb(0 0 0);\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n.iconContainer:hover {   \n    box-shadow: 4px 4px 10px rgb(255 87 7);\n}\n\n.icons {\n    width: 60px;    \n}\n.icons:hover {\n    width: 70px;    \n}\n\n.titleContainer {} \n\n.description {\n    font-family: system-ui;\n    font-size: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Menu_extraSmall_PortraitAndLanscape_container__04PBq`,
	"subContainer": `Menu_extraSmall_PortraitAndLanscape_subContainer__nstBk`,
	"menuObjectsContainer": `Menu_extraSmall_PortraitAndLanscape_menuObjectsContainer__P9r6o`,
	"renderMenu_extraSmall_PortraitAndLanscapeObjectsContainer": `Menu_extraSmall_PortraitAndLanscape_renderMenu_extraSmall_PortraitAndLanscapeObjectsContainer__bCgTK`,
	"iconContainer": `Menu_extraSmall_PortraitAndLanscape_iconContainer__ATjE4`,
	"icons": `Menu_extraSmall_PortraitAndLanscape_icons__UC6w3`,
	"titleContainer": `Menu_extraSmall_PortraitAndLanscape_titleContainer__u9TpV`,
	"description": `Menu_extraSmall_PortraitAndLanscape_description__n2N2s`
};
export default ___CSS_LOADER_EXPORT___;
