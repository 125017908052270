// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPTwo_container__0iHPm {    
    width: 100%;
    height: 100%;    
}

.SkillsPTwo_htmlContentSmallImg0__WsfYf {
    width: 100%;    
    height: 100%;
    cursor: pointer;
}

.SkillsPTwo_htmlContentSmallImg1__M0YAW {
    width: 118px;
    height: 118px;
    cursor: pointer;
}

.SkillsPTwo_htmlContentSmallImg2__v6w8e {
    width: 253px;   
    height: 100%; 
    margin: 0px 0px -19px -5px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPTwo.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".container {    \n    width: 100%;\n    height: 100%;    \n}\n\n.htmlContentSmallImg0 {\n    width: 100%;    \n    height: 100%;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg1 {\n    width: 118px;\n    height: 118px;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg2 {\n    width: 253px;   \n    height: 100%; \n    margin: 0px 0px -19px -5px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPTwo_container__0iHPm`,
	"htmlContentSmallImg0": `SkillsPTwo_htmlContentSmallImg0__WsfYf`,
	"htmlContentSmallImg1": `SkillsPTwo_htmlContentSmallImg1__M0YAW`,
	"htmlContentSmallImg2": `SkillsPTwo_htmlContentSmallImg2__v6w8e`
};
export default ___CSS_LOADER_EXPORT___;
