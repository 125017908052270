// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePagePSeven_extraSmall_container__Vy0Qn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;    
}

.HomePagePSeven_extraSmall_subContainer__fTTei {
    width: 80%;
    height: 80%;
}

.HomePagePSeven_extraSmall_backgroundImg__6L-Lw {
    width: 100%;
}

.HomePagePSeven_extraSmall_textsContainer__egbz0 {}

.HomePagePSeven_extraSmall_text1Container__HGDqJ {
    font-family: system-ui;
    font-size: 62%;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.HomePagePSeven_extraSmall_text2Container__vUMtP {
    font-family: system-ui;
    font-size: 62%;
    font-weight: 300;
    margin: 3% 0px 3% 0px;
    color: black;
}

.HomePagePSeven_extraSmall_textFocus__4Bk9h {
    color: #7794bb;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/HomePage/HomePagePSeven_extraSmall.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA,kDAAiB;;AAEjB;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;    \n}\n\n.subContainer {\n    width: 80%;\n    height: 80%;\n}\n\n.backgroundImg {\n    width: 100%;\n}\n\n.textsContainer {}\n\n.text1Container {\n    font-family: system-ui;\n    font-size: 62%;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.text2Container {\n    font-family: system-ui;\n    font-size: 62%;\n    font-weight: 300;\n    margin: 3% 0px 3% 0px;\n    color: black;\n}\n\n.textFocus {\n    color: #7794bb;\n    font-weight: 600;\n    margin: 0px 5px 0px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePagePSeven_extraSmall_container__Vy0Qn`,
	"subContainer": `HomePagePSeven_extraSmall_subContainer__fTTei`,
	"backgroundImg": `HomePagePSeven_extraSmall_backgroundImg__6L-Lw`,
	"textsContainer": `HomePagePSeven_extraSmall_textsContainer__egbz0`,
	"text1Container": `HomePagePSeven_extraSmall_text1Container__HGDqJ`,
	"text2Container": `HomePagePSeven_extraSmall_text2Container__vUMtP`,
	"textFocus": `HomePagePSeven_extraSmall_textFocus__4Bk9h`
};
export default ___CSS_LOADER_EXPORT___;
