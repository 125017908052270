// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentViewerPatch3_container__WEK\\+v {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ContentViewerPatch3_rectangularStrokeContainer__HrFfA {
    width: 95%;
    height: 95%;
    border-style: solid;
    border-color: white;
    border-width: 4px;
    border-radius: 3px;
}

.ContentViewerPatch3_contentContainer__0vzOq {
    width: 99%;
    height: 99%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;
    margin: .5% 0px 0px 0.5%;
}

.ContentViewerPatch3_backgroundContainer__BSkvT {
    position: absolute;
    width: 100.5%;
    height: 100%;
    overflow: hidden;
    border-radius: 25px;
    opacity: 0.8;
    filter: blur(0px);
    z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/contentViewer/ContentViewerPatch3.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,2BAA2B;IAC3B,oBAAoB;IACpB,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.rectangularStrokeContainer {\n    width: 95%;\n    height: 95%;\n    border-style: solid;\n    border-color: white;\n    border-width: 4px;\n    border-radius: 3px;\n}\n\n.contentContainer {\n    width: 99%;\n    height: 99%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: flex-start;\n    align-items: stretch;\n    margin: .5% 0px 0px 0.5%;\n}\n\n.backgroundContainer {\n    position: absolute;\n    width: 100.5%;\n    height: 100%;\n    overflow: hidden;\n    border-radius: 25px;\n    opacity: 0.8;\n    filter: blur(0px);\n    z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContentViewerPatch3_container__WEK+v`,
	"rectangularStrokeContainer": `ContentViewerPatch3_rectangularStrokeContainer__HrFfA`,
	"contentContainer": `ContentViewerPatch3_contentContainer__0vzOq`,
	"backgroundContainer": `ContentViewerPatch3_backgroundContainer__BSkvT`
};
export default ___CSS_LOADER_EXPORT___;
