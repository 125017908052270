// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HoverComponentPatch1_container__hJ0mL {
    width: 100%;
    height: 100%;
    position: relative;
}

.HoverComponentPatch1_isHoverContainer__pfCF1 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
    width: 468px;
    height: 138px;
    border-radius: 7px;
    border-width: 2px;
    border-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/HoverComponentPatch1.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.isHoverContainer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-color: white;\n    z-index: 2;\n    width: 468px;\n    height: 138px;\n    border-radius: 7px;\n    border-width: 2px;\n    border-style: solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HoverComponentPatch1_container__hJ0mL`,
	"isHoverContainer": `HoverComponentPatch1_isHoverContainer__pfCF1`
};
export default ___CSS_LOADER_EXPORT___;
