// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComingSoonLoader_loaderContainer__eKdA3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    margin: -4% 0px 0px 0px;  
  }
  
  .ComingSoonLoader_loader__CKluV {
    font-size: 2rem;
    font-family: system-ui;
    display: flex;
    align-items: center;
  }
  
  .ComingSoonLoader_dashes__kSOuT, .ComingSoonLoader_arrow__mRkds {
    white-space: pre;
    margin-left: 0.5rem;
    color: rgb(235, 152, 108);
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/components/loaders/ComingSoonLoader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":[".loaderContainer {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    margin: -4% 0px 0px 0px;  \n  }\n  \n  .loader {\n    font-size: 2rem;\n    font-family: system-ui;\n    display: flex;\n    align-items: center;\n  }\n  \n  .dashes, .arrow {\n    white-space: pre;\n    margin-left: 0.5rem;\n    color: rgb(235, 152, 108);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `ComingSoonLoader_loaderContainer__eKdA3`,
	"loader": `ComingSoonLoader_loader__CKluV`,
	"dashes": `ComingSoonLoader_dashes__kSOuT`,
	"arrow": `ComingSoonLoader_arrow__mRkds`
};
export default ___CSS_LOADER_EXPORT___;
