// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlassModal_container__0huti {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.GlassModal_isOpenContainer__oVDR1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.GlassModal_modalOverlay__YEKLb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GlassModal_modalContent__kcWPc {
  width: 100%;
  height: 100%;
  position: relative;
}

.GlassModal_modalContentGlass__\\+WaUg {
  background-color: rgba(255, 255, 255, 0.9);
  /* semi-transparent white */
  padding: 20px;
  border-radius: 10px;
  box-shadow: -19px 20px 20px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  opacity: 50%;
}

.GlassModal_closeModalImg__5uUTO {
  position: relative;
  width: 30px;
  top: -8px;
  right: calc(-100% + 26px);
  cursor: pointer;
  z-index: 1;
}

.GlassModal_closeModalImg__5uUTO:hover {
  background-color: #ff7a7a;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/modals/GlassModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C,kCAA0B;UAA1B,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,mDAAmD;EACnD,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,yBAAyB;EACzB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.isOpenContainer {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.5);\n  backdrop-filter: blur(4px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 4;\n}\n\n.modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modalContent {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.modalContentGlass {\n  background-color: rgba(255, 255, 255, 0.9);\n  /* semi-transparent white */\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: -19px 20px 20px 20px rgba(0, 0, 0, 0.1);\n  position: absolute;\n  opacity: 50%;\n}\n\n.closeModalImg {\n  position: relative;\n  width: 30px;\n  top: -8px;\n  right: calc(-100% + 26px);\n  cursor: pointer;\n  z-index: 1;\n}\n\n.closeModalImg:hover {\n  background-color: #ff7a7a;\n  border-radius: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GlassModal_container__0huti`,
	"isOpenContainer": `GlassModal_isOpenContainer__oVDR1`,
	"modalOverlay": `GlassModal_modalOverlay__YEKLb`,
	"modalContent": `GlassModal_modalContent__kcWPc`,
	"modalContentGlass": `GlassModal_modalContentGlass__+WaUg`,
	"closeModalImg": `GlassModal_closeModalImg__5uUTO`
};
export default ___CSS_LOADER_EXPORT___;
