// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderListOfImagesOneByOne_container__jxlfL {    
    border-radius: 10px;
    width: 100%; /* Make the image fill the width of the parent container */
    height: 100%; /* Make the image fill the height of the parent container */
    object-fit: cover; /* Maintain aspect ratio and cover the entire container */
    animation: RenderListOfImagesOneByOne_renderingAnimation__Xv80G 2s ease-in-out; /* Add animation */
  }

/* Define Animations Section */
@keyframes RenderListOfImagesOneByOne_renderingAnimation__Xv80G {
    0% {
        opacity: 10%;
        filter: blur(5px);
    }
    50% {
      opacity: 60%;
    }
    100% {
        opacity: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/modules/components/presentationViewer/RenderListOfImagesOneByOne.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW,EAAE,0DAA0D;IACvE,YAAY,EAAE,2DAA2D;IACzE,iBAAiB,EAAE,yDAAyD;IAC5E,8EAA4C,EAAE,kBAAkB;EAClE;;AAEF,8BAA8B;AAC9B;IACI;QACI,YAAY;QACZ,iBAAiB;IACrB;IACA;MACE,YAAY;IACd;IACA;QACI,aAAa;IACjB;EACF","sourcesContent":[".container {    \n    border-radius: 10px;\n    width: 100%; /* Make the image fill the width of the parent container */\n    height: 100%; /* Make the image fill the height of the parent container */\n    object-fit: cover; /* Maintain aspect ratio and cover the entire container */\n    animation: renderingAnimation 2s ease-in-out; /* Add animation */\n  }\n\n/* Define Animations Section */\n@keyframes renderingAnimation {\n    0% {\n        opacity: 10%;\n        filter: blur(5px);\n    }\n    50% {\n      opacity: 60%;\n    }\n    100% {\n        opacity: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RenderListOfImagesOneByOne_container__jxlfL`,
	"renderingAnimation": `RenderListOfImagesOneByOne_renderingAnimation__Xv80G`
};
export default ___CSS_LOADER_EXPORT___;
