// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomShape_container__enDR0 {
    width: 100%;
    height: 100%;
    position: relative;    
  }
  
.CustomShape_svg__d7LUT {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
  }
  
.CustomShape_shape__Lu7Ij {    
    fill: transparent;
    stroke: #000;
    stroke-width: 1;    
  }
  
.CustomShape_foreignObject__yKyez {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/modules/components/shapes/CustomShape.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;EACd;;AAEF;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;EACjB;;AAEF;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;EACvB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;    \n  }\n  \n.svg {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n  }\n  \n.shape {    \n    fill: transparent;\n    stroke: #000;\n    stroke-width: 1;    \n  }\n  \n.foreignObject {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomShape_container__enDR0`,
	"svg": `CustomShape_svg__d7LUT`,
	"shape": `CustomShape_shape__Lu7Ij`,
	"foreignObject": `CustomShape_foreignObject__yKyez`
};
export default ___CSS_LOADER_EXPORT___;
