// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckDetailsComponentTransformation_container__t2GB2 {
    width: 100%;
    height: 100%;
    position: relative;
}

.CheckDetailsComponentTransformation_contentContainerDefault__NKJyT {
    width: 100%;
    height: 100%;
}
.CheckDetailsComponentTransformation_contentContainerBlur__wXPL9 {    
    width: 100%;
    height: 100%;
    filter: blur(1px);
    opacity: 80%;    
}

.CheckDetailsComponentTransformation_icon__NM7gN {
    width: 10px;
    height: 18px;
    position: absolute;
    top: 39%;
    left: 39%;    
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/CheckDetailsComponentTransformation.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,eAAe;AACnB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.contentContainerDefault {\n    width: 100%;\n    height: 100%;\n}\n.contentContainerBlur {    \n    width: 100%;\n    height: 100%;\n    filter: blur(1px);\n    opacity: 80%;    \n}\n\n.icon {\n    width: 10px;\n    height: 18px;\n    position: absolute;\n    top: 39%;\n    left: 39%;    \n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CheckDetailsComponentTransformation_container__t2GB2`,
	"contentContainerDefault": `CheckDetailsComponentTransformation_contentContainerDefault__NKJyT`,
	"contentContainerBlur": `CheckDetailsComponentTransformation_contentContainerBlur__wXPL9`,
	"icon": `CheckDetailsComponentTransformation_icon__NM7gN`
};
export default ___CSS_LOADER_EXPORT___;
