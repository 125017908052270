// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoffeePThree_container__IZ8mj {
  width: 100%;        
  height: calc(65vh - 70px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;               
}

.CoffeePThree_listOfReviewsContainer__Yrk\\+j {
  width: 85%;
  height: 100%;  
}

.CoffeePThree_addButton__6Hgre {
width: 45%;
cursor: pointer;
animation: CoffeePThree_focusTextAnimation__0BkrX 1.5s forwards;
}

/* [ANIMATION SECTION] */
@keyframes CoffeePThree_focusTextAnimation__0BkrX {
from {    
  opacity: 0;
  filter: blur(5px);
}
to {    
  opacity: 100%;
}
}`, "",{"version":3,"sources":["webpack://./src/pages/Coffee/CoffeePThree.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;AACA,UAAU;AACV,eAAe;AACf,+DAA2C;AAC3C;;AAEA,wBAAwB;AACxB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA","sourcesContent":[".container {\n  width: 100%;        \n  height: calc(65vh - 70px);\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: center;\n  align-items: center;               \n}\n\n.listOfReviewsContainer {\n  width: 85%;\n  height: 100%;  \n}\n\n.addButton {\nwidth: 45%;\ncursor: pointer;\nanimation: focusTextAnimation 1.5s forwards;\n}\n\n/* [ANIMATION SECTION] */\n@keyframes focusTextAnimation {\nfrom {    \n  opacity: 0;\n  filter: blur(5px);\n}\nto {    \n  opacity: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CoffeePThree_container__IZ8mj`,
	"listOfReviewsContainer": `CoffeePThree_listOfReviewsContainer__Yrk+j`,
	"addButton": `CoffeePThree_addButton__6Hgre`,
	"focusTextAnimation": `CoffeePThree_focusTextAnimation__0BkrX`
};
export default ___CSS_LOADER_EXPORT___;
