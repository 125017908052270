// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonV2_container__yIzkQ {
    width: 100%;
    height: 100%;
    position: relative;
} 

.ButtonV2_titleContainer__LwOLw {    
    width: 100%;    
    margin: 0px 0px 3px 0px;
} 

.ButtonV2_title__KSQFz {} 

.ButtonV2_backgroundContainer__MgDzc {    
    width: 89%;
    height: 50%;
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
} 
`, "",{"version":3,"sources":["webpack://./src/modules/components/buttons/ButtonV2.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B;;AAEA,wBAAQ;;AAER;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n} \n\n.titleContainer {    \n    width: 100%;    \n    margin: 0px 0px 3px 0px;\n} \n\n.title {} \n\n.backgroundContainer {    \n    width: 89%;\n    height: 50%;\n    overflow: hidden;\n    border-style: solid;\n    border-width: 1px;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonV2_container__yIzkQ`,
	"titleContainer": `ButtonV2_titleContainer__LwOLw`,
	"title": `ButtonV2_title__KSQFz`,
	"backgroundContainer": `ButtonV2_backgroundContainer__MgDzc`
};
export default ___CSS_LOADER_EXPORT___;
