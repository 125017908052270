// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultPageScheduler_container__XxdaN {
  width: 100vw;
  height: calc(100vh - 64px);
  overflow-x: hidden;
}

.DefaultPageScheduler_preContainer__mNpp5 {
    width: 100vw;
    height: calc(100vh - 64px);
    overflow-x: hidden;
} 

.DefaultPageScheduler_mainContainer__7ibfI {
    width: 100vw;
    height: calc(100vh - 64px);
    overflow-x: hidden;    
}

.DefaultPageScheduler_mainContainerOpenGlassModal__1\\+XIR {
  width: 100vw;
  height: calc(100vh - 64px);
  overflow-x: hidden;
  position: relative;
  top: -100vh;
}

.DefaultPageScheduler_postContainer__4xUPd {
  width: 100%;
  height: 50%;
  margin: 30px 0px 0px 0px;
}

.DefaultPageScheduler_postContainer_OpenGlassModal__Elc42 {
  width: 100%;
  height: 50%;
  margin: 30px 0px 0px 0px;
  position: relative;
  top: -100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/DefaultPageScheduler.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,wBAAwB;EACxB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".container {\n  width: 100vw;\n  height: calc(100vh - 64px);\n  overflow-x: hidden;\n}\n\n.preContainer {\n    width: 100vw;\n    height: calc(100vh - 64px);\n    overflow-x: hidden;\n} \n\n.mainContainer {\n    width: 100vw;\n    height: calc(100vh - 64px);\n    overflow-x: hidden;    \n}\n\n.mainContainerOpenGlassModal {\n  width: 100vw;\n  height: calc(100vh - 64px);\n  overflow-x: hidden;\n  position: relative;\n  top: -100vh;\n}\n\n.postContainer {\n  width: 100%;\n  height: 50%;\n  margin: 30px 0px 0px 0px;\n}\n\n.postContainer_OpenGlassModal {\n  width: 100%;\n  height: 50%;\n  margin: 30px 0px 0px 0px;\n  position: relative;\n  top: -100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DefaultPageScheduler_container__XxdaN`,
	"preContainer": `DefaultPageScheduler_preContainer__mNpp5`,
	"mainContainer": `DefaultPageScheduler_mainContainer__7ibfI`,
	"mainContainerOpenGlassModal": `DefaultPageScheduler_mainContainerOpenGlassModal__1+XIR`,
	"postContainer": `DefaultPageScheduler_postContainer__4xUPd`,
	"postContainer_OpenGlassModal": `DefaultPageScheduler_postContainer_OpenGlassModal__Elc42`
};
export default ___CSS_LOADER_EXPORT___;
