// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultResponsivePageHandler_container__v9VrV {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/responsivePages/DefaultResponsivePageHandler.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DefaultResponsivePageHandler_container__v9VrV`
};
export default ___CSS_LOADER_EXPORT___;
