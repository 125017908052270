// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowUpWithJumpscareEfect_container__IVQOA { 
    width: 100%;
    height: 100%;   
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/ShowUpWithJumpscareEfect.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container { \n    width: 100%;\n    height: 100%;   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ShowUpWithJumpscareEfect_container__IVQOA`
};
export default ___CSS_LOADER_EXPORT___;
