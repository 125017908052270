// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowUpListWithCornersEffect_container__9knpL {
    margin: 15% 0% 0% 15%;
}

.ShowUpListWithCornersEffect_contentImage__B3XR4 {
    position: absolute;
    max-width: 300px;
    left: -86%;
    top: -108%;
    border-radius: 5%;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/showupViewer/ShowUpListWithCornersEffect.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".container {\n    margin: 15% 0% 0% 15%;\n}\n\n.contentImage {\n    position: absolute;\n    max-width: 300px;\n    left: -86%;\n    top: -108%;\n    border-radius: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ShowUpListWithCornersEffect_container__9knpL`,
	"contentImage": `ShowUpListWithCornersEffect_contentImage__B3XR4`
};
export default ___CSS_LOADER_EXPORT___;
