// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsPNine_container__HIe4O {    
    width: 100%;
    height: 100%;    
}

.SkillsPNine_htmlContentSmallImg0__nwuBW {
    width: 100%;    
    height: 100%;
    cursor: pointer;
}

.SkillsPNine_htmlContentSmallImg1__nXXUD {
    width: 118px;
    height: 118px;
    cursor: pointer;
}

.SkillsPNine_htmlContentSmallImg2__A3pff {
    width: 253px;   
    height: 100%; 
    margin: 0px 0px -19px -62px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Skills/SkillsPNine.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,eAAe;AACnB","sourcesContent":[".container {    \n    width: 100%;\n    height: 100%;    \n}\n\n.htmlContentSmallImg0 {\n    width: 100%;    \n    height: 100%;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg1 {\n    width: 118px;\n    height: 118px;\n    cursor: pointer;\n}\n\n.htmlContentSmallImg2 {\n    width: 253px;   \n    height: 100%; \n    margin: 0px 0px -19px -62px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsPNine_container__HIe4O`,
	"htmlContentSmallImg0": `SkillsPNine_htmlContentSmallImg0__nwuBW`,
	"htmlContentSmallImg1": `SkillsPNine_htmlContentSmallImg1__nXXUD`,
	"htmlContentSmallImg2": `SkillsPNine_htmlContentSmallImg2__A3pff`
};
export default ___CSS_LOADER_EXPORT___;
