// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetaDataViewer_container__K-NXt {
    width: 100%;
    height: 100%;
}

.MetaDataViewer_infoImageContainer__BSA3E {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.MetaDataViewer_infoImage__2qcgM {
    width: 65%;
    opacity: 50%;
    filter: blur(3px);
    filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.5));
}

.MetaDataViewer_contentContainer__8Gswx {
    width: 100%;
    height: 100%;
}

.MetaDataViewer_noMetaDataContainer__59Lvk {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/detailsViewer/MetaDataViewer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,oDAAoD;AACxD;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n}\n\n.infoImageContainer {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.infoImage {\n    width: 65%;\n    opacity: 50%;\n    filter: blur(3px);\n    filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.5));\n}\n\n.contentContainer {\n    width: 100%;\n    height: 100%;\n}\n\n.noMetaDataContainer {\n    width: 100%;\n    height: 80%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MetaDataViewer_container__K-NXt`,
	"infoImageContainer": `MetaDataViewer_infoImageContainer__BSA3E`,
	"infoImage": `MetaDataViewer_infoImage__2qcgM`,
	"contentContainer": `MetaDataViewer_contentContainer__8Gswx`,
	"noMetaDataContainer": `MetaDataViewer_noMetaDataContainer__59Lvk`
};
export default ___CSS_LOADER_EXPORT___;
